var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        "auto-complete": "on",
        model: _vm.module,
        rules: _vm.rules,
        "label-width": "160px",
      },
    },
    [
      _c(
        "content-block",
        { attrs: { title: "Module" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Type", prop: "type_id" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.types.loading,
                      expression: "types.loading",
                    },
                  ],
                  attrs: {
                    value: _vm._module.type.id,
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(255, 255, 255, 0.7)",
                  },
                  on: { change: _vm.updateType },
                },
                _vm._l(_vm.types.items, function (type) {
                  return _c("el-option", {
                    key: type.id,
                    attrs: { label: type.value, value: type.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._module.type.id
            ? _c(
                "el-form-item",
                { attrs: { label: "Framework", prop: "subtype_id" } },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.subtypes.loading,
                          expression: "subtypes.loading",
                        },
                      ],
                      attrs: {
                        value: _vm.subtypeId,
                        "element-loading-spinner": "el-icon-loading",
                        "element-loading-background":
                          "rgba(255, 255, 255, 0.7)",
                      },
                      on: { change: _vm.updateSubtype },
                    },
                    _vm._l(_vm.subtypes.items, function (type) {
                      return _c("el-option", {
                        key: type.id,
                        attrs: { label: type.value, value: type.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Repository", prop: "repository" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            value: _vm.module.workspace,
                            placeholder: "Workspace",
                            loading: _vm.loading.workspaces,
                            filterable: "",
                            "default-first-option": "",
                          },
                          on: { change: _vm.selectWorkspace },
                        },
                        _vm._l(_vm.workspaces, function (workspace) {
                          return _c("el-option", {
                            key: workspace.uuid,
                            attrs: {
                              value: workspace.slug,
                              label: workspace.name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.workspaces.length > 0
                    ? [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "center",
                              opacity: "0.5",
                            },
                            attrs: { span: 1 },
                          },
                          [_vm._v(" : ")]
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _vm.projects.length > 0 || _vm.loading.projects
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      "default-first-option": "",
                                      value: _vm._module.project,
                                      loading: _vm.loading.projects,
                                    },
                                    on: { change: _vm.selectProjectGroup },
                                  },
                                  _vm._l(_vm.projects, function (proj) {
                                    return _c("el-option", {
                                      key: proj.uuid,
                                      attrs: {
                                        value: proj.name,
                                        label: proj.name,
                                      },
                                    })
                                  }),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.projects.length > 0
                    ? [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "text-align": "center",
                              opacity: "0.5",
                            },
                            attrs: { span: 1 },
                          },
                          [_vm._v(" : ")]
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _vm.repositories.length > 0 ||
                            _vm.loading.repositories
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      "default-first-option": "",
                                      value: _vm._module.repository,
                                      loading: _vm.loading.repositories,
                                    },
                                    on: { change: _vm.selectRepository },
                                  },
                                  _vm._l(_vm.repositories, function (repo) {
                                    return _c("el-option", {
                                      key: repo.uuid,
                                      attrs: {
                                        value: repo.name,
                                        label: repo.name,
                                      },
                                    })
                                  }),
                                  1
                                )
                              : _vm.all_repositories.length > 0
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      "default-first-option": "",
                                      value: _vm._module.repository,
                                      loading: _vm.loading.repositories,
                                    },
                                    on: { change: _vm.selectRepository },
                                  },
                                  _vm._l(
                                    _vm.all_repositories_grouped,
                                    function (group) {
                                      return _c(
                                        "el-option-group",
                                        {
                                          key: group.project.key,
                                          attrs: {
                                            label: `[${group.project.key}] ${group.project.name}`,
                                          },
                                        },
                                        _vm._l(group.repos, function (repo) {
                                          return _c("el-option", {
                                            key: repo.uuid,
                                            attrs: {
                                              value: repo.name,
                                              label: repo.name,
                                            },
                                          })
                                        }),
                                        1
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Notes", prop: "notes" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 2 },
                on: { change: _vm.updateNotes },
                model: {
                  value: _vm.module.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.module, "notes", $$v)
                  },
                  expression: "module.notes",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._module.repository
        ? [
            _c(
              "content-block",
              { attrs: { title: "Environments" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: { float: "right" },
                    attrs: { slot: "right" },
                    slot: "right",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.handleNewEnvironment },
                      },
                      [
                        _vm.create_env
                          ? _c("span", [_vm._v("Cancel")])
                          : _c("span", [_vm._v("Add")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.create_env
                  ? _c(
                      "div",
                      { staticClass: "create" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Name" } },
                          [
                            _c(
                              "el-row",
                              { staticClass: "row" },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.env_name,
                                        callback: function ($$v) {
                                          _vm.env_name = $$v
                                        },
                                        expression: "env_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        on: {
                                          click: _vm.handleCreateNewEnvironment,
                                        },
                                      },
                                      [_vm._v(" Create ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.environments, function (env) {
                  return _c(
                    "div",
                    { key: env, staticClass: "environment" },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "header", attrs: { label: env } },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                trigger: "click",
                                "popper-class": "options-popover",
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "options-trigger",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("svgicon", {
                                    attrs: { name: "settings" },
                                  }),
                                ],
                                1
                              ),
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    staticStyle: { color: "red" },
                                    on: {
                                      click: () =>
                                        _vm.handleRemoveEnvironment(env),
                                    },
                                  },
                                  [_vm._v(" Remove ")]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("hr"),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Branch" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "default-first-option": "",
                                value: _vm._module.environments[env].branch,
                                loading: _vm.loading.branches,
                              },
                              on: {
                                change: (val) => _vm.updateBranch(env, val),
                              },
                            },
                            _vm._l(_vm.branches, function (branch) {
                              return _c("el-option", {
                                key: branch.name,
                                attrs: {
                                  value: branch.name,
                                  label: branch.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }